import AHealthy from './Picture4.png';
import BMA from './Picture5.png';
import IPRO from './Picture6.png';
import ESCON from './Picture7.gif';
import IKB from './Picture8.png';
import PUTSCH from './Picture9.gif';
import SUDZUCKER from './Picture10.svg';
import BABBINI from './Picture11.png';
import FILOX from './Picture12.png';
import BWS from './Picture13.jpg';
import SIEMENS from './Picture14.png';
import ANDRITZ from './Picture15.png';
import VDL from './Picture16.png';
import AKIRA from './Picture17.jpg';
import GPE from './Picture18.png';
import SCHMIDT from './Picture19.png';
import Stern from './Picture20.png';

export const data = {
    title: "Cooperation", card:
        [
            {
                id: 1,
                link: "http://www.ahealthyst.com/",
                logo: AHealthy,
                name: "Agile Smart Health Technology"

            },
            {
                id: 2,
                link: "https://www.bma-worldwide.com/de/",
                logo: BMA,
                name: "BMA Braunschweigische Maschinenbauanstalt AG"
            },
            {
                id: 3,
                link: "https://ipro-bs.de/",
                logo: IPRO,
                name: "IPRO Industrieprojekt GmbH"
            },
            {
                id: 4,
                link: "http://www.escon-gmbh.com",
                logo: ESCON,
                name: "ESCON Engineering Services and Consulting GmbH"
            },
            {
                id: 5,
                link: "http://www.ikb-planung.de/",
                logo: IKB,
                name: "IKB Industrieplanung- Konstruktionen-Bauplanung GmbH"
            },
            {
                id: 6,
                link: "https://en.putsch.com/",
                logo: PUTSCH,
                name: "Putsch Group"
            },
            {
                id: 7,
                link: "https://www.suedzuckergroup.com/de",
                logo: SUDZUCKER,
                name: "Südzucker"
            },
            {
                id: 8,
                link: "http://www.babbinipresses.com/en/",
                logo: BABBINI,
                name: "Babbini ITALIA"
            },
            {
                id: 9,
                link: "https://filox.de/",
                logo: FILOX,
                name: "Riedel Filtertechnik GmbH"
            },
            {
                id: 10,
                link: "https://bwsit.com",
                logo: BWS,
                name: "BWS Technologie"
            },
            {
                id: 11,
                link: "https://www.siemens.com/global/en.html",
                logo: SIEMENS,
                name: "SIEMENS"
            },
            {
                id: 12,
                link: "https://www.andritz.com/group-en",
                logo: ANDRITZ,
                name: "ANDRITZ"
            },
            {
                id: 13,
                link: "https://www.vdldelmas.com/en",
                logo: VDL,
                name: "VDL Delmas"
            },
            {
                id: 14,
                link: "http://akiraseeds.es/eng/int.htm",
                logo: AKIRA,
                name: "AKIRA SEEDS"
            },
            {
                id: 15,
                link: "https://gpe-turbo.de/",
                logo: GPE,
                name: "GPE Turbo"
            },
            {
                id: 16,
                link: "https://schmidt-haensch.com/",
                logo: SCHMIDT,
                name: "SCHMIDT + HAENSCH GmbH & Co."
            },
            {
                id: 17,
                link: "https://sternenzym.de/en/",
                logo: Stern,
                name: "Stern Enzym"
            },
        ]
};