export const data = {
    header: "PRIVACY POLICE",
    title: "Access Data",
    info: [
        {
            id: 1,
            text: `The website operator or website provider collects data about accesses to the site and saves these as "server   
        log files". The following data is logged:`,
        },
        {
            id: 2,
            text: `· Visited website`
        },
        {
            id: 3,
            text: `· Time at the time of access`
        },
        {
            id: 4,
            text: `· Amount of data sent in bytes`
        },
        {
            id: 5,
            text: `· Source / reference from which you came to the page`
        },
        {
            id: 6,
            text: `· Used browser`
        },
        {
            id: 7,
            text: `· Operating system used`
        },
        {
            id: 8,
            text: `· Used IP address`
        },
        {
            id: 9,
            text: `The data collected are for statistical purposes only and to improve the website. However, the website
        operator reserves the right to    retrospectively check the server logfiles should concrete evidence point to      
        unlawful use.`
        },
        {
            id: 10,
            text: `Handling of personal data
        The website operator collects, uses and transfers your personal data only if this is permitted by law or if you
        consent to the collection of data.`
        },
        {
            id: 11,
            text: `Personal information means all information that is used to identify your person and which can be traced back
        to you - such as your name, e-    mail address and telephone number.`
        },
        {
            id: 12,
            text: `Handling contacts information
        If you contact the website operator through the offered contact options, your details will be stored so that they can be used to process and    answer your request. Without your consent, this data will not be disclosed to third parties.        
        Rights of the user: information, correction and deletion
        As a user, you will receive, at your request, free information about which personal data has been stored about you. If your request does not    conflict with a legal obligation to retain data (eg data retention), you are entitled to correct incorrect data and to block or delete your personal data.`
        }
    ]
};
