export const data = {
    header:"about us",
    info:[
    {
        id:1,
        text: `The CET-Co is independent and collaborative engineering consultancy,
   specialising in the engineering and design of sugar factory equipment and processes.`,
    },
    {
        id:2,
        text: `CET-Co is intenational company that owned and managed by experienced sugar factory engineers 
    and technologists with in depth first-hand knowledge of the complete sugar production process.`
    },
    {
        id:3,
        text: `CET-Co provides engineering design, equipment supply, global procurement, technical advisory services, project management ,
 consultancy services in many countries across all continents and also provide the best solutions, regardless of scale.`
    },
    {
        id:4,
        text:`We are active in Europe, Asia and etc.`
    }
]};
