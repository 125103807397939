import { useEffect } from "react";
import { createContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./helper/theme";
import { Main } from "./helper/style";
import Home from "./components/home";
import ContactUs from "./components/contactUS";
import Coopration from "./components/coopration";
import AboutUs from "./components/about";
import Trading from "./components/trading";
import Sidebar from "./components/sideBar";
import Consulations from "./components/consulations";
import Privacy from "./components/privacy";
import './App.css';

export const IsOpenContext = createContext(null);

function App() {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    var w = window.innerWidth;
    if (w < 768) {
      setIsOpen(false);
    }
  }, []);

  return (
    <ThemeProvider theme={{ ...theme, fontFamily: "Montserrat" }}>
      <IsOpenContext.Provider value={[isOpen, setIsOpen]}>
        <div className="App">
          <BrowserRouter>
            <Sidebar />
            <Main isOpen={isOpen}>
              <Routes>
                <Route element={<Home />} path="/"></Route>
                <Route element={<AboutUs />} path="/about"></Route>
                <Route element={<Consulations />} path="/consulations"></Route>
                <Route element={<Consulations />} path="/engineering"></Route>
                <Route element={<Trading />} path="/trading"></Route>
                <Route element={<Coopration />} path="/coopration"></Route>
                <Route element={<ContactUs />} path="/contact"></Route>
                <Route element={<Privacy />} path="/privacy"></Route>
              </Routes>
            </Main>
          </BrowserRouter>
        </div>
      </IsOpenContext.Provider>
    </ThemeProvider>
  );
}

export default App;
