import FirstBg from './firstBg.png';
import SecondBg from './secondBg.png';
import MainImg from './mainImg.png';

export const data = {
    header: "consulting engeneering trade coopration company",
    body: "The CET-Co is independent and collaborative engineering consultancy, specialising in the engineering and design of sugar factory equipment and processes.",
    firstBg: FirstBg,
    secondBg: SecondBg,
    mainImg: MainImg,
}
