import { data } from './data';
import { CopyRight, CopyRightLink } from '../../helper/style';
const Footer = () => {
    return (
        <CopyRight>{data.info}
            <CopyRightLink
                href={data.link.url}
                target="blank"
            >
                {data.link.text}
            </CopyRightLink>
        </CopyRight>);
}
export default Footer;

