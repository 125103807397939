import Group from './Group3.png';
export const data = {
    header: "Trading",
    heroImage: Group, 
    contexts: {
        contents: [{
            id: 1,
            text: `new and used industrial goods`
        }, {
            id: 2,
            text: `Used and new machinery for the sugar industry`
        }, {
            id: 3,
            text: `Auxiliary materials and additives for the food industry, feed industry, chemical industry, paper industry, petrochemical industry, oil and refinery industry and pharmaceutical industry,`
        }, {
            id: 4,
            text: `Sale Citric acid`
        }, {
            id: 5,
            text: `Sale Sodium bicarbonate food grade`
        }, {
            id: 6,
            text: `Sale Saffron and other herbal medicine of Iran`
        }, {
            id: 7,
            text: `Sale of crocin`
        }, {
            id: 8,
            text: `Sale medicinal herbs`
        }]
    },

    
};
