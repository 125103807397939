import { useContext } from "react";
import { IsOpenContext } from "../../App";
import {
    Container, MenuButton, Logo,
    Menu, MenuItem, MenuLink, SildeBarWrapper
} from "./styles";
import { data } from "./content/data";
import Footer from "../footer";

const Sidebar = () => {
    const [isOpen, setIsOpen] = useContext(IsOpenContext);
    const uptoTop = () => {
        var w = window.innerWidth;
        if (w < 768) {
            setIsOpen(false);
        }
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    return (<Container isOpen={isOpen}>

        <MenuButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
            <hr />
            <hr />
            <hr />
        </MenuButton>
        <Logo isOpen={isOpen} />
        <SildeBarWrapper isOpen={isOpen}>
            <Menu>
                <MenuItem>
                    {data.menu.map(({ name, link }) => (<MenuLink key={name} to={link} onClick={uptoTop}>{name}</MenuLink>))}
                </MenuItem>
            </Menu>
            <Footer />
        </SildeBarWrapper>
    </Container>)
};
export default Sidebar;