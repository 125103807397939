export const data = {
    menu: [
        {
            name: "home",
            link: "/",
        },
        {
            name: "About us",
            link: "/about",
        },
        {
            name: "Consulations",
            link: "/consulations",
        },
        {
            name: "Engineering",
            link: "/engineering",
        },
        {
            name: "Trading",
            link: "/trading",
        },
        {
            name: "Coopration",
            link: "/coopration",
        },
        {
            name: "contact us",
            link: "/contact",
        },
        {
            name: "PRIVACY POLICE",
            link: "/privacy",
        },
    ]
};
