import { Container, ContentPrivacy, Header, ContentWrapper, TitlePrivacy } from "../../helper/style";
import { data } from "./content/data";

const Privacy = () => (<Container>
    <Header>{data.header}</Header>
    <TitlePrivacy>{data.title}</TitlePrivacy>
    <ContentWrapper>
        {data.info.map(({ text, id }) => <ContentPrivacy key={id}>{text}</ContentPrivacy>)}
    </ContentWrapper>
</Container>);
export default Privacy;