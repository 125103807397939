import { Container, LiContent, Header, HeroImage, Title, Wrapper, Divider } from "../../helper/style";
import { data } from "./content/data";
const RenderContent = () => 
    data.contexts.map(( {heroImage, title, id, contents }) => (
        <Wrapper  key={id}>
            <Title>{title}</Title>
            <ul>
                {contents.map(({id, text }) => (<LiContent key={id}>{text}</LiContent>))}
            </ul>
            <HeroImage src={heroImage} alt={title} />
            <Divider/>
        </Wrapper>
    ));
const Consulations = () => (
    <Container>
        <Header>{data.header}</Header>
        <RenderContent />
    </Container>)
export default Consulations;