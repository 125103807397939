import Email from './email.png';
import Map from './map.png';
import Mobile from './mobile.png';
import Phone from './phone.png';
import Tax from './tax.png';

export const data = {
    header: "Contact us",
    info: [
        {
            id: 1,
            name: "mobile",
            value: "+49 151 54 690 360",
            icon: Mobile 
        },
        {
            id: 2,
            name: "phone",
            value: "+49 30 7978330-133",
            icon: Phone 
        },
        {
            id: 3,
            name: "e-mail",
            value: "elahi@cet-co.eu",
            icon:  Email 
        },
        {
            id: 4,
            name: "Tax. Nr",
            value: "29/358/31061",
            icon: Tax 
        },
        {
            id: 5,
            name: "location",
            value: `Schlossstrasse 48a\nD-12165 Berlin\nHandelsregister Berlin\nHRB 181914 B`,
            icon: Map 
        }
    ]
};