import { Container, Content, Header, Grid, HomeMedia, MainImage, FirstShap, SecondShap, Divider } from "../../helper/style";
import { data } from './content/data';

const Home = () => (<Container>
    <Header>{data.header}</Header>
    <Grid>
        <Content>{data.body}</Content>
        <Divider />
        <HomeMedia>
            <FirstShap className="FirstShap" src={data.firstBg} alt={data.header} />
            <MainImage src={data.mainImg} alt={data.header} />
            <SecondShap className="SecondShap" src={data.secondBg} alt={data.header} />
        </HomeMedia>
    </Grid>
</Container>)
export default Home;