import { Container, Grid, Header, ContentWrapper, Card, CardImage, CardText ,Parent} from "../../helper/style";
import { data } from "./content/data";

const listOfCooperation = data.card.map(({ id, link, logo, name }) => (

    <Card href={link} key={id}>
        <CardImage src={logo} alt={name} ></CardImage>
        <CardText>{name}</CardText>
    </Card>
));

const Coopration = () => ( 
<Container>
    <Header>
        {data.title}
    </Header> 

        <Grid>
            {listOfCooperation}
        </Grid> 

</Container>)
export default Coopration;  