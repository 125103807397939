import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import logo from './content/logo.svg';
import { media } from "../../helper/grid";


export const Container = styled.div`
    background: ${({ theme }) => theme.navyBlue};
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: ${props => props?.isOpen ?  "18rem" : "2rem" };
    align-items: stretch;
    color: ${({ theme }) => theme.white};
    position: fixed;
    z-index: 12;
    transition: all 0.8s ease-in-out;
    left: ${props => props?.isOpen ?  0 : "-1rem" };
    align-content: center;
    min-height: 100%;
    height: 100%;
    ${media.from.tablet`
        min-height: 100vh;
         position: relative;
         height: auto;
    `}
`;

export const MenuButton = styled.div`
    width: 1rem;
    height: 2rem;
    background: ${({ theme }) => theme.navyBlue};
    border-radius: 0 2rem 2rem 0;
    padding: 1rem;
    position: absolute;
    right: ${props => props?.isOpen ?  "-2rem" : "-2.5rem" };
    top: 1rem;
    cursor: pointer;
    transition: all 0.8s ease-in-out;
    z-index:2;
    ${media.from.tablet`
        width: 2rem;
        right: ${props => props?.isOpen ?  "-4rem" : "-3.5rem" };
    `}
`;


export const Logo = styled.div`
    background-image: url(${logo});
    height: 14rem;
    background-repeat: no-repeat;
    background-position: center; 
    display: ${props => props?.isOpen ?  "block" : "none" };
    transition: all 0.8s ease-in-out;
`;

export const Menu = styled.ul`
  `;

export const MenuItem = styled.li`
    list-style: inside none;
    display: flex;
    flex-direction: column;
    line-height: 3.5rem;
`;

export const MenuLink = styled(NavLink)`
    color: ${({ theme }) => theme.white};
    text-decoration: none;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;
    &:hover {
        color: ${({ theme }) => theme.green};
        transition: all 0.5s ease-in-out;
    }
`;
export const SildeBarWrapper = styled.div`
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    display: ${props => props?.isOpen ?  "flex" : "none" };
    transition: all 0.8s ease-in-out;
`;