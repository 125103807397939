import styled from 'styled-components';
import { media } from "./grid";

export const Header = styled.h1`
    font-family:"Jost";
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 1.8rem;
    margin-top: 5rem;
    border-bottom: 0.1rem solid  ${({ theme }) => theme.navyGray};
    padding: 0.5rem;
    text-transform: capitalize;
    ${media.from.tablet`
        margin-bottom: 2.8rem;
        font-size: 2rem;
        margin-top: 0;
    `}
`;

export const Title = styled.h2`
    font-family:"Jost";
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    margin-botton: 0.5rem;
    color: ${({ theme }) => theme.navyBlue};
    ${media.from.tablet`
       font-size: 1.6rem;
       margin-botton: 2.5rem;`}
`;
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
`;

export const Content = styled.p`
    font-family:"Jost";
    font-size: 1rem;
    line-height: 1.75rem;
    text-align: left;
    color: ${({ theme }) => theme.navyBlue}; 
    padding: 0.2rem;
    ${media.from.tablet`
        padding: 1rem;
        font-size: 1.5rem;
        line-height: 2.25rem;
    `}
`;
export const LiContent = styled.li`
    font-family:"Jost";
    font-size: 1rem;
    line-height: 1.75rem;
    text-align: left;
    color: ${({ theme }) => theme.navyBlue};
    ${media.from.tablet`
        font-size: 1.25rem;
        line-height: 2.25rem;
    `}
`;

export const Divider = styled.div`
    width: 30%;
    height: 0.1rem;
    background: ${({ theme }) => theme.lightGray};
    margin: 3rem auto;
`;


export const Container = styled.div`
    margin: 1rem 2.5rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    ${media.from.tablet`
        margin: 3rem 5rem;
    `}
`;


export const Main = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    // left:  ${props => props?.isOpen ? "-2rem" : 0};
    transition: all 0.8s ease-in-out;
    // ${media.from.tablet`
    //     left: ${props => props?.isOpen ? 0 : "-18rem"};
    // `}
`;


export const HomeMedia = styled.div`
    position:relative;
    
`;

export const FirstShap = styled.img`
    position: absolute;
    z-index: -1;
    right: 0;
    width: 8rem;;
    ${media.from.tablet`
      top: 10rem;
      right: 10rem;
      width: 14rem;
  ;
    `}
`;

export const SecondShap = styled.img`
    position: absolute;
    z-index: -1;
    left: -3rem;
    bottom: -1rem;
`;

export const MainImage = styled.img`
    width: 100%;
`;

export const HeroImage = styled.img`
    box-shadow: 6px 5px 18px 7px  ${({ theme }) => theme.lightGray};
    width: 60%;
`;

export const HeroImageFull = styled.img`
    width: 80%;
`;

export const Grid = styled.div`
    width: 100%;
    display: flex; 
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    ${media.from.tablet`
        margin: 1rem;
        padding: 1rem;
    `}
`;

export const Card = styled.a` 
    width: 9rem;
    height: 9rem;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
    border: 0.15rem solid ${({ theme }) => theme.extraLightGray};
    border-radius: 1rem;
    box-shadow: 0px 5px 6px 2px  ${({ theme }) => theme.lightGray};
    &:hover{
        box-shadow: 0px 5px 6px 2px ${({ theme }) => theme.navyBlue};
        transition: all 0.8s ease-in-out;
    }
    ${media.from.tablet`
        width: 12rem;
        height: 14rem;
        padding: 2rem;
    `}
`;

export const CardImage = styled.img`
    width: 100%;  
`;


export const CardText = styled.p`

    
`;

export const Wrapper = styled.div`
    width: 100%;
    margin: 2rem 0;
`;

export const Parent = styled.div`
    display: flex;
    background: red;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
`;

export const ContactBox = styled.div`
    border: 1px solid gray;
    border-radius: 25px;
    position: relative;
    padding: 2rem;
    min-width: 14rem;
    margin: 1rem;
`;

export const BoxImage = styled.img`
    position: absolute;
    top: -2rem;
    left: 1rem;
    background: white;
`;
export const BoxTitle = styled.h2`
    text-align: left;
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: bold;
`;
export const BoxInfo = styled.p`
    text-align: left;
    padding: 1rem;
    font-size: 1rem;
    font-weight: bold;
    white-space: pre;
    line-height: 1.75rem;
    ${media.from.tablet`
        font-size: 1.2rem;
        padding: 0.75rem;
     `}
`;

export const CopyRight = styled.span`
    font-size: 0.65rem;
    color: white;
    padding: 0.75rem;
    line-height: 1rem;
`;
export const CopyRightLink = styled.a`
    font-size: 0.65rem;
    color: ${({ theme }) => theme.lightGray};
`

export const TitlePrivacy = styled.h2`
    font-family:"Jost";
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    margin-botton: 0.5rem;
    color: ${({ theme }) => theme.navyBlue};
    ${media.from.tablet`
       margin-botton: 2.5rem;`}
`;
export const ContentPrivacy = styled.p`
    font-family:"Jost";
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: left;
    color: ${({ theme }) => theme.navyBlue}; 
    ${media.from.tablet`
        line-height: 1.75rem;
    `}
`;