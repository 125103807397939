const currentYear = new Date().getFullYear();
console.log(currentYear)
export const data = {
    info: `Copyright © `+ currentYear+` CET-CO All Rights Reserved |
     Product by `,

    link: {
        url: "https://www.linkedin.com/in/elham-baradaran-heravi/",
        text: "Elham B-Heravi"
    }
};