import { Container, Header, ContactBox, BoxImage, BoxInfo, BoxTitle, Grid } from "../../helper/style";
import { data } from "./content/data";

const ContactUs = () => (<Container>
    <Header>{data.header}</Header>
    <Grid>
        {data.info.map(({ id, icon, name, value }) => (
            <ContactBox key={id}>
                <BoxImage src={icon} alt={name} />
                <BoxTitle>{name}</BoxTitle>
                <BoxInfo>{value}</BoxInfo>
            </ContactBox>

        ))}
    </Grid>
</Container>);
export default ContactUs;