import { Container, Header, HeroImageFull, LiContent, Title, Wrapper } from "../../helper/style";
import { data } from "./content/data";

const Trading = () => (<Container>
    <Header>{data.header}</Header>     
        <Wrapper>
        <Title> {data.contexts.title}</Title>
        <ul>
            {data.contexts.contents.map(({ id, text }) => (<LiContent key={id}>{text}</LiContent>))}
        </ul>
        </Wrapper>
        <HeroImageFull src={data.heroImage} alt={data.header}></HeroImageFull>

</Container>)
export default Trading;