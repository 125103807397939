export const theme = {
    green: "#009449",
    navyBlue: "#03021F",
    white: "#ffffff",
    black: "#000000",
    darkGray: "#03021F",
    lightGray:"#c5c5c5",
    navyGray: "#c7c7d1",
    extraLightGray:"#f1f1ff"
}
const sizes = {
    maxWidth: "1440px"
  };

export default {
    theme,
    sizes
  }