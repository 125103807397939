import Pic1 from './Picture1.png';
import Pic2 from './Picture2.png';

export const data = {
    header: "Consultation & Engineering",
    contexts: [{
        id: 1,
        title: "Sugar Technology",
        heroImage: Pic1,
        contents: [{
            id: 1,
            text: `Complex engineering of beet, cane sugar and refining technologies`
        }, {
            id: 2,
            text: `Engineering services from feasibility studies to basic and detailed engineering`
        }, {
            id: 3,
            text: `Interdisciplinary planning tailored to local conditions: emissions, water scarcity, alternative heat sources, etc.`
        }]
    },
    {
        id: 2,
        title: "Technical elaboration of turnkey projects (EPC)",
        heroImage: Pic2,
        contents: [{
            id: 1,
            text: ` Optimization of existing sugar plants`
        }, {
            id: 2,
            text: `Sugar quality projects`
        }, {
            id: 3,
            text: `Economic evaluation of sugar projects`
        }, {
            id: 4,
            text: `Scale-up of sugar processing capacity`
        }]
    }]
};
